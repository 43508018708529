import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/reset.css'

const container = document.getElementById('root');
if (process.env.REACT_APP_ENV !== "development" && process.env.REACT_APP_ENV !== undefined) console.log = () => {};
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(

        <App />

);


