import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GetLicensesServices from "services/GetLicensesServices";

export const initialState = {
    loading: false,
    error: false,
    loaded:false,
    licenses: [],

    status: "idle",
    message: "",
    redirect: "",
};

export const fetchLicenses = createAsyncThunk("license/fetch", async (data, { rejectWithValue }) => {
        console.log("fetchLicenses")
        try {

            const risultato = await GetLicensesServices.getLicenses();



            return risultato;

        } catch (err) {

            return rejectWithValue(err.response?.data?.message || "Error");

        }
    }
);

export const licenseSlice = createSlice({name: "license", initialState, reducers: {showLoading: (state) => {state.loading = true;}},extraReducers: (builder) => {
        builder
            .addCase(fetchLicenses.pending, (state) => {
                state.loading = true;
                state.loaded = false;
                state.error=false;
            })
            .addCase(fetchLicenses.fulfilled, (state, action) => {
                state.loading = false;
                state.loaded = true;
                state.error=false;

                state.redirect = "/";
                state.token = action.payload;
                state.licenses = action.payload.risposta;

            })
            .addCase(fetchLicenses.rejected, (state, action) => {
                state.loading = false;
                state.loaded = true;
                state.error=true;

                state.message = action.payload;
                state.licenses = []
                state.showMessage = true;



            });
    },
});

export default licenseSlice.reducer;
