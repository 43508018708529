import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'challange_new_password',
        path: `${AUTH_PREFIX_PATH}/new-password`,
        component: React.lazy(() => import('views/auth-views/authentication/challenge-response')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.distribuzioni',
        path: `${APP_PREFIX_PATH}/dashboards/distribuzioni`,
        component: React.lazy(() => import('views/app-views/dashboards/distribuzioni')),
    },
    {
        key: 'success',
        path: `${APP_PREFIX_PATH}/success`,
        component: React.lazy(() => import('views/app-views/result-page/success-page')),
    },
    {
        key: 'error',
        path: `${APP_PREFIX_PATH}/canceled`,
        component: React.lazy(() => import('views/app-views/result-page/canceled-page')),
    }

]