import fetch from 'auth/FetchInterceptor'
import { env } from 'configs/EnvironmentConfig'
const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/',
		method: 'post',
		data: {
			"AuthFlow": "USER_PASSWORD_AUTH",
			"AuthParameters": {"USERNAME": data.email,"PASSWORD": data.password},
			"ClientId": env.aws_user_pools_web_client_id
		},
		headers:{"X-Amz-Target":"AWSCognitoIdentityProviderService.InitiateAuth"}
	})
}

AuthService.challengeNewPassword = function (data){
	return fetch({
		url: '/',
		method: 'post',
		data: {
			"ChallengeName": "NEW_PASSWORD_REQUIRED",
			"ChallengeResponses":  {
				"NEW_PASSWORD": data.password,
				"USERNAME": data.email
			},
			"ClientId": env.aws_user_pools_web_client_id,
			"Session": data.sessionId
		},
		headers:{"X-Amz-Target":"AWSCognitoIdentityProviderService.RespondToAuthChallenge"}
	})
}






export default AuthService;