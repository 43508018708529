const dev = {
	API_ENDPOINT_URL: 'https://kkimeme643.execute-api.eu-central-1.amazonaws.com/latest',
	API_AUTH_URL:'https://cognito-idp.eu-central-1.amazonaws.com',
	aws_cognito_region: 'eu-central-1',
	aws_user_pools_web_client_id: '6p4d8ml6icghi0o8ht0d8e2p8f',
};

const coll = {
	API_ENDPOINT_URL: 'https://k6aic1ibla.execute-api.eu-central-1.amazonaws.com/latest',
	API_AUTH_URL:'https://cognito-idp.eu-central-1.amazonaws.com',
	aws_cognito_region: 'eu-central-1',
	aws_user_pools_web_client_id: '21a7htr5723t3mvkr0d5bo5b8g',
};

const prod = {
	API_ENDPOINT_URL: 'https://djk1sgs3jd.execute-api.eu-central-1.amazonaws.com/latest',
	API_AUTH_URL:'https://cognito-idp.eu-central-1.amazonaws.com',
	aws_cognito_region: 'eu-central-1',
	aws_user_pools_web_client_id: '3a0hje8mt3rrhtp4q51i860n9h',
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'sviluppo':
			return dev
		case 'collaudo':
			return coll
		case 'produzione':
			return prod
		default:
			return {
				API_ENDPOINT_URL: 'http://localhost:8080',
				API_AUTH_URL:'https://cognito-idp.eu-central-1.amazonaws.com',
				aws_cognito_region: 'eu-central-1',
				aws_user_pools_web_client_id: '6p4d8ml6icghi0o8ht0d8e2p8f',
			};
	}
}

export const env = getEnv()
