import fetch from "auth/GenericFetchInterceptor";

const GetLicensesServices = {};

GetLicensesServices.getLicenses = function () {
  return fetch({
    url: `/audiomedical/get-all-licenses`,
    method: "GET",
  });
};

export default GetLicensesServices;
