import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import licenseSlice from './slices/licenseSlice'
import distribution from 'store/slices/distributionSlice'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        licenseSlice,
        distribution,
       //qua si aggiungono gli slices che crei
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
