import fetch from "auth/GenericFetchInterceptor";

const DistributionServices = {};

DistributionServices.getDist = function () {
  return fetch({
    url: `/audiomedical/get-dists`,
    method: "GET",
  });
};

export default DistributionServices;
