import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DistributionServices from "services/DistributionService";

export const initialState = {
    loadingDist: false,
    errorDist: false,
    loadedDist:false,
    dist: [],
};

export const fetchDist = createAsyncThunk("dist/fetch", async (data, { rejectWithValue }) => {

        try {

            const risultato = await DistributionServices.getDist();
            return risultato.risposta;

        } catch (err) {

            return rejectWithValue(err.response?.data?.message || "Error");

        }
    }
);

export const distributionSlice = createSlice({name: "distribution", initialState, reducers: {showLoading: (state) => {state.loading = true;}},extraReducers: (builder) => {
        builder
            .addCase(fetchDist.pending, (state) => {
                state.loadingDist = true;
                state.loadedDist = false;
                state.errorDist=false;
            })
            .addCase(fetchDist.fulfilled, (state, action) => {
                state.loadingDist = false;
                state.loadedDist = true;
                state.errorDist=false;

                state.dist=action.payload

            })
            .addCase(fetchDist.rejected, (state, action) => {
                state.loadingDist = false;
                state.loadedDist = true;
                state.errorDist=true;

                state.dist=action.payload



            });
    },
});

export default distributionSlice.reducer;
