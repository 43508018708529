import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'


export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	expire:localStorage.getItem("auth_token_expire") || null,
	loginEmail:localStorage.getItem("auth_email") || null,
}

export const signIn = createAsyncThunk('auth/login',async (data, { rejectWithValue }) => {
	const { email, password } = data

	try {
		let response = await AuthService.login({email, password})
		const challenge = (response?.ChallengeName==="NEW_PASSWORD_REQUIRED")

		if(challenge){

			const sessionID=response.Session
			window.location.href = `${AUTH_PREFIX_PATH}/new-password?sessionId=${sessionID}&email=${email}`;

		}else {
			const token = response.AuthenticationResult.IdToken
			const expire = response.AuthenticationResult.ExpiresIn
			//TODO mettere in ascolto un timer che disconnette quando scade
			const loginEmail=JSON.parse(atob(token?.split('.')[1])).email
			localStorage.setItem(AUTH_TOKEN, token);
			localStorage.setItem("auth_token_expire", expire);
			localStorage.setItem("auth_email", loginEmail);
			return {token,expire,loginEmail};
		}


	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/singUp',async (data, { rejectWithValue }) => {
	console.log("dataSignUp",data)
	const {values,sessionId} = data;
	const {email,password}=values;
	try {
		console.log("email",email)
		console.log("password",password)
		console.log("password",password)
		if(email!=undefined && password!=undefined && sessionId!=undefined) {
			let response = await AuthService.challengeNewPassword({"email":email, "password":password,"sessionId":sessionId})
			const token = response.AuthenticationResult.IdToken
			const expire = response.AuthenticationResult.ExpiresIn
			const loginEmail=JSON.parse(atob(token?.split('.')[1])).email
			//TODO mettere in ascolto un timer che disconnette quando scade
			localStorage.setItem(AUTH_TOKEN, token);
			localStorage.setItem("auth_token_expire", expire);
			localStorage.setItem("auth_email", loginEmail);
			return {token,expire,loginEmail};
		}else{
			console.error("email or password or sessionId undefined")
			return rejectWithValue('Error')
		}

	} catch (err) {
		console.error(err)
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
	localStorage.clear();
})





export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload.token
			state.expire=action.payload.expire
			state.loginEmail=action.payload.loginEmail
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.expire=null
			state.loginEmail=null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload.token
			state.expire=action.payload.expire
			state.loginEmail=action.payload.loginEmail
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.expire=action.payload.expire
				state.loginEmail=action.payload.loginEmail
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.expire=null
				state.loginEmail=null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.expire = null
				state.loginEmail=null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload.token
				state.expire=action.payload.expire
				state.loginEmail=action.payload.loginEmail
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})

	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer