import React, { lazy, Suspense, memo,useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { ConfigProvider } from 'antd';
import Loading from 'components/shared-components/Loading';
import { lightTheme, darkTheme } from 'configs/ThemeConfig';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes'

import {fetchLicenses} from "../store/slices/licenseSlice";
import {fetchDist} from 'store/slices/distributionSlice';
import {signOut} from "../store/slices/authSlice";

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
	const dispatch = useDispatch()
	const token = useSelector(state => state.auth.token);
	const blankLayout = useSelector(state => state.theme.blankLayout);

	const Layout = token && !blankLayout ? AppLayout : AuthLayout;

	const locale = useSelector(state => state.theme.locale);

	const direction = useSelector(state => state.theme.direction);

	const currentTheme = useSelector(state => state.theme.currentTheme);

	const currentAppLocale = resources[locale];

	useBodyClass(`dir-${direction}`);

	const themeConfig = currentTheme === 'light' ? {...lightTheme} : {...darkTheme}
	useEffect(() => {
		if(token!=null) {
			//console.log("useEffectNavSearch",JSON.parse(atob(token?.split('.')[1])))
			const expirationTimeStamp = JSON.parse(atob(token?.split('.')[1])).exp
			const expirationDate = new Date(expirationTimeStamp * 1000); // Converti il timestamp in millisecondi
			console.log('Il token scadrà il:', expirationDate);

			const currentTimestamp = Math.floor(Date.now() / 1000); // Ottieni il timestamp UNIX corrente in secondi
			if (currentTimestamp > expirationTimeStamp) {
				dispatch(signOut())
			} else {
				console.log('useEffectNavSearch Il token è ancora valido.');
				dispatch(fetchLicenses())
				dispatch(fetchDist())
				const timer = setTimeout(() => {
					// Azioni da eseguire una volta che il timer è scaduto
					console.log('Il timer è scaduto!');
					dispatch(signOut())
				}, (expirationTimeStamp-currentTimestamp) * 1000);
				return () => clearTimeout(timer);
			}

		}
	}, [token]);

	return (
		<ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
			<Suspense fallback={<Loading cover="content"/>}>
				<Layout>
					<Routes />
				</Layout>
			</Suspense>
		</ConfigProvider>
	)
}

export default memo(Layouts)